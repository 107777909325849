import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Heading, Paragraph } from "../../../design-system";
import { ResponsiveLayout } from "../../_components/layout/responsive-layout";
import { LearnMore } from "../../_components/navigation/learn-more";
import { Section } from "../../_components/section";
import { leftContent, leftContentBleed, rightContent, rightContentBleed, section } from "./hydrogen-utilities.css";

export const HydrogenUtilitiesSection = () => <ResponsiveLayout nodes={[<MobileLayout />, <DesktopLayout />]} />;

const MobileLayout = () => (
  <>
    <Section>
      <GreenHydrogenIntro />
    </Section>
    <StaticImage
      src={`../../../assets/images/infrastructure/curved-windmill-power-mobile.png`}
      placeholder="blurred"
      objectFit={"fill"}
      alt=""
    />
    <Section>
      <HydrogenRefuellingIntro />
    </Section>
    <StaticImage
      src={`../../../assets/images/infrastructure/curved-truck-highway-mobile.png`}
      placeholder="blurred"
      layout={"fullWidth"}
      objectFit={"fill"}
      alt=""
    />
  </>
);

const DesktopLayout = () => (
  <>
    <section className={section}>
      <div className={leftContent}>
        <GreenHydrogenIntro />
      </div>
      <div className={rightContentBleed}>
        <StaticImage
          src={`../../../assets/images/infrastructure/curved-windmill-power.png`}
          placeholder="blurred"
          objectFit={"cover"}
          height={650}
          alt=""
        />
      </div>
    </section>
    <section className={section}>
      <div className={leftContentBleed}>
        <StaticImage
          src={`../../../assets/images/infrastructure/curved-truck-highway.png`}
          placeholder="blurred"
          objectFit={"cover"}
          height={650}
          alt=""
        />
      </div>
      <div className={rightContent}>
        <HydrogenRefuellingIntro />
      </div>
    </section>
  </>
);

const GreenHydrogenIntro = () => (
  <>
    <Heading as={"h3"} size={["lg", "xl"]} mb={["lg", "none"]} flexGrow={[0, 1]}>
      Clean hydrogen with localised generation
    </Heading>
    <Paragraph mb={"xl"}>
      Using world leading hydrogen technologies, H2FA will generate hydrogen from on-site based solar energy.
    </Paragraph>
    <LearnMore />
  </>
);

const HydrogenRefuellingIntro = () => (
  <>
    <Heading as={"h3"} size={["lg", "xl"]} mb={["lg", "none"]} flexGrow={[0, 1]}>
      Hydrogen refuelling for commercial vehicles
    </Heading>
    <Paragraph mb={"xl"}>
      Hydrogen fuelled commercial vehicles will contribute to the transition to clean energy on Australian roads. The
      local production of hydrogen at H2FA’s site will bring both environmentally and economically sustainable benefits
      to Australia.
    </Paragraph>
    <LearnMore />
  </>
);
