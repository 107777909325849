import * as React from "react";
import styled from "styled-components";
import { Text } from "../../../atoms/typography";
import { Theme } from "../../../core/app.theme";
import { VerticalSpacer } from "../../../gls/spacer";
import { Section, SectionProps } from "./section";

export const SectionInfo = (props: SectionProps) => <Section backgroundColor={"icywaters"} {...props} />;

export const SectionInfoQuoteText = (props: { quote?: string; author?: string }) => (
  <div>
    <SectionInfoText>
      {props.quote && <q>{props.quote}</q>}
    </SectionInfoText>
    <VerticalSpacer space={"lg"} />
    <TextQuoteAuthor>{props.author}</TextQuoteAuthor>
  </div>
);

export const SectionInfoText = styled(Text)({
  fontWeight: Theme.fontWeight("book"),
  fontFamily: Theme.fontFamily("monument"),
  fontSize: Theme.fontSize("md"),
  textAlign: "center",
  color: Theme.color("ghostgumwhite"),
  [Theme.media("lg")]: {
    fontSize: Theme.fontSize("xl"),
  },
});

export const TextQuoteAuthor = styled(Text)({
  fontSize: Theme.fontSize("xs"),
  fontWeight: Theme.fontWeight("bold"),
  textAlign: "center",
  color: Theme.color("ghostgumwhite"),
  textTransform: "uppercase",
  [Theme.media("lg")]: {
    fontSize: Theme.fontSize("sm"),
  },
});
