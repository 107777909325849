import * as React from "react";
import { Heading, Paragraph, Paragraphs } from "../../components/atoms/typography";
import { Responsive } from "../../components/gls/responsive";
import { StretchSpacer, VerticalSpacer } from "../../components/gls/spacer";
import { LearnMore } from "../../components/templates/common/button";
import { Section } from "../../components/templates/page/section/section";

export const HomeSectionIntro = () => (
  <Section>
    <Responsive>
      <Heading fontSize={"4xl"} maxWidth={600}>
        Contributing to a clean hydrogen future
      </Heading>
      <StretchSpacer />
      <Paragraphs>
        <Paragraph maxWidth={450}>
          Hydrogen Fuels Australia explains, demonstrates, adds value, and introduces efficiencies across the clean
          hydrogen value chain.
        </Paragraph>
        <Paragraph maxWidth={450}>We are end-to-end clean hydrogen project and implementation experts.</Paragraph>
        <Paragraph maxWidth={450}>
          Hydrogen Fuels Australia supports governments in policy design, governance, and the effective development of
          regulatory frameworks and safety notice updates.
        </Paragraph>
        <Paragraph maxWidth={450}>
          Commercially, across a range of projects, Hydrogen Fuels Australia conducts viability and cost-benefit
          assessments, preparing business cases and comparing clean hydrogen fuels, Low Carbon Liquid Fuels (LCLF), and
          electric vehicle (EV) operations.
        </Paragraph>
        <VerticalSpacer />
        <LearnMore />
      </Paragraphs>
    </Responsive>
  </Section>
);
